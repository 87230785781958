// import './Section.css';
// import * as CONSTANTS from './constants'
import DOMPurify from 'dompurify'
import React from 'react';
import Badge from 'react-bootstrap/Badge'
import {getTags, getMedias} from '../utils/queries';
import MediaContainer from './MediaContainer';

const badge_class_map = {
    "MOTIVE": "primary",
    "TOPIC": "info",
    "TECH": "secondary"
}

export default class Project extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            tags: [],
            medias: []
        };
    }

    componentDidMount() {
        getTags((data) => {
            this.setState({tags: data})
        }, this.props.projectData.id);
        getMedias((data) => {
            this.setState({medias: data})
        }, this.props.projectData.id);
    }

    render() {
        return (
        <div>
            <h2>{this.props.projectData.name}</h2>
            <p>
                {this.state.tags.map((tag, index) => {
                    return <React.Fragment key={index}>
                        <Badge variant={badge_class_map[tag.type]}>{tag.name}</Badge>{' '}
                        </React.Fragment>
                })}
            </p>
            <p style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.projectData.description)}}></p>

            {this.state.medias.length > 0 ? <MediaContainer medias={this.state.medias} />:null}
        </div>
        )
    }
}