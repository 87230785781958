import {logdebug} from './utils'

const esc = encodeURIComponent;

// const DB_HOST = "http://helium.local:3001"
const DB_HOST = "https://acourchesne.sparkpi.ca/queries";

export function buildQueryString(route = undefined, params = {}) {
    /**
     * Build a snanitized GET query string
     */
    let baseURL = [DB_HOST, route].filter(Boolean).join("/")
    let url = new URL(baseURL);
    Object.keys(params).map(k => url.searchParams.append(esc(k), esc(params[k])))
    return url.toString()
}

export async function getProjects(cb) {
    const queryStr = buildQueryString("projects")
    logdebug("Sending query to "+queryStr)
    fetch(queryStr)
        .then((resp) => resp.json())
        .then((data) => {
            cb(data)
        })
        .catch(error => {
            console.error(
                "There has been a problem with your fetch operation:",
                error
            );
        });
}

export async function getTags(cb, projectID) {
    const queryStr = buildQueryString("gettags", { "projectID": projectID })
    logdebug("Sending query to "+queryStr)
    fetch(queryStr)
        .then((resp) => resp.json())
        .then((data) => {
            cb(data);
        })
        .catch(error => {
            console.error(
                "There has been a problem with your fetch (fetch) operation:",
                error
            );
        });
};

export async function getMedias(cb, projectID) {
    const queryStr = buildQueryString("getmedias", { "projectID": projectID })
    logdebug("Sending query to "+queryStr)
    fetch(queryStr)
        .then((resp) => resp.json())
        .then((data) => {
            cb(data);
        })
        .catch(error => {
            console.error(
                "There has been a problem with your fetch (fetch) operation:",
                error
            );
        });
};

// exports = {
//     buildQueryString,
//     getProjects,
//     getTags,
//     getMedias,
// }