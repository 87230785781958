import './Section.css';
// import * as CONSTANTS from './constants'
import React from 'react';

export default class Section extends React.Component {

    render() {

        var sectionClass;
        if (this.props.position === "1") {
            sectionClass = "section-header1";
        } else if (this.props.position === "2") {
            sectionClass = "section-header2";
        } else {
            sectionClass = (this.props.position % 2 === 0) ? "section-even" : "section-odd";
        }

        return <div className={"Section " + sectionClass}>
            <div className="centered">
                {this.props.children}
            </div>
        </div>
    }
}