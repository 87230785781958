import "./Footer.css"
import React from 'react';


export default class Footer extends React.Component {

    render() {

        return (
            <footer className="footer text-center">
                <p>Hosted on Arch Linux Arm - Raspberry Pi | Project by Anthony Courchesne 2021</p>
            </footer>
        );
    }
}