import './NavBar.css'
import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import lilogo from '../assets/liicon.png'
import mailicon from '../assets/mailicon2.png'
import ghicon from '../assets/ghiconw.png'


export default class MyNavBar extends React.Component {

    render() {

        return (
        <Navbar className="color-nav" expand="lg">
            <Navbar.Brand href="#home">ACourchesne</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                <Nav.Link href="#home">CV</Nav.Link>
                <Nav.Link href="#projects">Projects</Nav.Link>
                <Nav.Link href="http://drive.sparkpi.ca">Drive</Nav.Link>
                </Nav>
            </Navbar.Collapse>

            <Nav.Link className="justify-content-end" href="https://github.com/CourchesneA">
                <img
                    src={ghicon}
                    width="30"
                    height="30"
                    className="d-inline-block align-middle"
                    alt="Mail contact"
                />
            </Nav.Link>
            <Nav.Link className="justify-content-end" href="https://www.linkedin.com/in/courchesnea/">
                <img
                    src={lilogo}
                    width="30"
                    height="30"
                    className="d-inline-block align-middle"
                    alt="Linkedin contact"
                />
            </Nav.Link>
            <Nav.Link className="justify-content-end" href="mailto:anthony.courchesne@mail.mcgill.ca">
                <img
                    src={mailicon}
                    width="40"
                    height="25"
                    className="d-inline-block align-middle"
                    alt="Mail contact"
                />
            </Nav.Link>
        </Navbar>
    )}
}