import React from 'react';
import Section from './Section';

export default class Header2 extends React.Component {

    render() {

        return (
            <header className="work-experience">
                <Section position="2">
                        <h2>Work experience</h2>
                        <hr></hr>
                        <ul>
                            <li>
                                <span className="fl">Innovative Vehicle Institute - Robotics & Machine Learning Project Manager</span>
                                <span className="fr">April 2021 - present</span>
                            </li>
                            <li>
                                <span className="fl">Robotics and Embodied AI Lab - Grad Student & Researcher</span>
                                <span className="fr">Jan. 2020 - present</span>
                            </li>
                            <li>
                                <span className="fl">Teaching assistant for robotics class at UdeM</span>
                                <span className="fr">Sept. 2020 - Dec. 2020</span>
                            </li>
                            <li>
                                <span className="fl">Amazon - Data science intern</span>
                                <span className="fr">May 2019 - July 2019</span>
                            </li>
                            <li>
                                <span className="fl">McGill Space Institute - Research Assistant</span>
                                <span className="fr">Sept. 2018 - Dec. 2018</span>
                            </li>
                            <li>
                                <span className="fl">Amazon - Software Engineer Intern</span>
                                <span className="fr">May 2018 - Aug. 2018</span>
                            </li>
                            <li>
                                <span className="fl">Fujitsu - Software Developer Intern </span>
                                <span className="fr">May 2017 - Aug. 2017</span>
                            </li>
                            <li>
                                <span className="fl">McGill Robotics - Software Developer (AUV division) </span>
                                <span className="fr">Sept. 2016 - Sept. 2017</span>
                            </li>
                            <li>
                                <span className="fl">Paradocs Solutions - Software Engineer</span>
                                <span className="fr">May 2016 - Nov. 2016</span>
                            </li>
                        </ul>

                </Section>
            </header>
        )
    }
}