// import logo from './logo.svg';
import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MyNavBar from './components/NavBar';
import Header1 from './components/Header1';
import Header2 from './components/Header2';
import Projects from './components/Projects';
import Footer from './components/Footer';

export default class App extends React.Component {
  render() {
    return (
      <div className="App">
        <MyNavBar />
        <Header1 />
        <Header2 />
        <Projects/>
        <Footer />
      </div>
    );
  }
}
