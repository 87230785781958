import './MediaContainer.css'
import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel'

export default class MediaContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getImgTag = this.getImgTag.bind(this)
    };

    componentDidMount() {
        this.props.medias.forEach((md, index) => {
            import(`../assets/${md.route}`).then(image => {
                this.setState({
                    [md.media_id]: image["default"]
                })
            })
            .catch(reason => {
                console.log(`Unable to get route for ${md.route}`)
            })
        })
    }

    getImgTag(media) {
        var img = this.state[media.media_id]

        if (img === undefined) {
            return (null)
        }else{
            return (
                <img className="media-container" src={img} alt={media.alt}/>
            )
        }
    }

    render() {
        if (this.props.medias.length > 1){
            return (
                <div>
                    {/* Slide=false remove animation due to temp bug in react-bootstrap strict mode */}
                    <Carousel slide={false}>
                        {this.props.medias.map((md, index) => {
                            return <Carousel.Item key={index}>
                               {this.getImgTag(md)}
                            </Carousel.Item>
                        })}
                    </Carousel>
                </div>
            );
        }
        else if (this.props.medias.length === 1){
            return this.getImgTag(this.props.medias[0])
        }
        else {
            return (null);
        }
    }
}

