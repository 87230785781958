import React from 'react';
import Section from './Section';
import Badge from 'react-bootstrap/Badge'


export default class Header1 extends React.Component {

    render() {

        return (
            <header className="Snip-description">
                <Section position="1">
                    <h1>Anthony Courchesne</h1>
                    <h3>
                        <Badge variant="secondary">Robotics</Badge>{" "}
                        <Badge variant="secondary">Machine Learning</Badge> {" "}
                        <Badge variant="secondary">Software Development</Badge>
                    </h3>
                    <br />
                    <hr />
                    <h2>Education</h2>
                    <p style={{ textAlign: "left", fontSize: "20px" }}>Mila - Research Master (ongoing)
                <span style={{ float: "right" }}>GPA: 4.0</span>
                    </p>
                    <p style={{ textAlign: "left", fontSize: "20px" }}>McGill - B.S. Computer Science
                <span style={{ float: "right" }}>GPA: 3.37</span>
                    </p>
                    <h2>
                        Publications
            </h2>
                    <ul>
                        <p>(IROS2020) <a href="https://arxiv.org/pdf/2009.04362.pdf">Integrated Benchmarking and Design for Reproducible and Accessible Evaluation of Robotic Agents</a></p>
                        <p>(RSS2020) <a href="https://sim2real.github.io/assets/papers/2020/paull.pdf">On Assessing the Value of Simulation for Robotics</a></p>
                    </ul>
                    <h2>
                        Outreach
            </h2>
                    <ul>
                        <p><a href="https://www.eventbrite.ca/e/billets-camp-ia-conference-anthony-courchesne-110845503886#">Camp AI presentation</a>: Introducing young students to artificial intelligence and autonomous vehicles using <a href="https://www.duckietown.org/">duckietown</a></p>
                    </ul>
                    <h2>
                        COVID-19 research contribution
            </h2>
                    <ul>
                        <p><a href="https://arxiv.org/pdf/2005.08502.pdf">COVI whitepaper</a>: Making pandemic simulation deterministic</p>
                    </ul>
                </Section>
            </header>
        )
    }
}