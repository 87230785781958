import React from 'react';
import Section from './Section';
import Project from './Project';
import Spinner from 'react-bootstrap/Spinner';
import {getProjects} from '../utils/queries';

export default class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = { projects: [] };
        this.status_report = this.status_report.bind(this)
    }

    componentDidMount() {
        getProjects((data) => {
            this.setState({projects: data})
        });
        if(this.state.projects.length === 0){
            setTimeout(this.status_report, 10000)
        }
    }

    status_report() {
        if(this.state.projects.length !== 0){
            return
        }
        var spinner = document.getElementById('spinner')
        spinner.innerHTML = `
            <p style="color:red;">
                Error while querying the database. Report sent to admin.
            </p>
        `
    }

    header(children) {
        return (
            <div className="section section-odd" id="projects">
                <div className="centered" style={{ paddingBottom: "10px" }}>
                    <h1>Projects</h1>
                    <hr></hr>
                    {children}
                </div>
            </div>
        )
    }

    render() {
        const projects = this.state.projects;
        if(projects.length === 0){
            return (
                <div>
                    {this.header(
                        <div id="spinner">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}
                </div>
            )
        }
        return (
            <div>
                {this.header()}
                {projects.map((project, index) => {
                    return <Section position={(index + 3).toString()} key={index.toString()}>
                        <Project projectData={project}></Project>
                    </Section>
                })}
                <div />
            </div>
        );
    }
}
